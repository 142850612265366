import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import { POSTCODE_VALIDATION_SUCCESS } from '../postcode'
import {
  referralCodeLens,
  referralErrorLens,
  referralInfoLens,
  referralIsAutoLens,
  referralsDataLens,
  referralsViewDataContentLens,
  showCreditInfoLens,
} from './lens'
import State, { referralsDefaultState as defaultState } from './state'
import {
  CHECK_REFERRAL_CODE_FAILURE,
  CHECK_REFERRAL_CODE_START,
  CHECK_REFERRAL_CODE_SUCCESS,
  CLEAR_REFERRAL_ERROR,
  CLEAR_REFERRAL_INFO,
  GET_REFERRAL_CODE_SUCCESS,
  RESET_REFERRAL,
  SET_REFERRAL_IS_AUTO,
  SET_VIEW_DATA_CONTENT,
  SHOW_CREDIT_INFO,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case GET_REFERRAL_CODE_SUCCESS:
      return referralsDataLens(state).set(action.payload)
    case CHECK_REFERRAL_CODE_START:
      return flow(
        referralCodeLens,
        l => l.set(action.payload),
        referralErrorLens,
        l => l.set(false),
        referralInfoLens,
        l => l.set(null),
      )(state)
    case CHECK_REFERRAL_CODE_FAILURE:
      return referralErrorLens(state).set(true)
    case CHECK_REFERRAL_CODE_SUCCESS:
      return referralInfoLens(state).set(action.payload)
    case RESET_REFERRAL:
      return flow(
        referralCodeLens,
        l => l.set(null),
        referralErrorLens,
        l => l.set(false),
        referralInfoLens,
        l => l.set(null),
        referralIsAutoLens,
        l => l.set(false),
      )(state)
    case SET_REFERRAL_IS_AUTO:
      return referralIsAutoLens(state).set(action.payload)
    case SET_VIEW_DATA_CONTENT:
      return referralsViewDataContentLens(state).set(action.payload)
    case SHOW_CREDIT_INFO:
      return showCreditInfoLens(state).set(action.payload)
    case CLEAR_REFERRAL_ERROR:
      return referralErrorLens(state).set(false)
    case CLEAR_REFERRAL_INFO:
      return referralInfoLens(state).set(null)
    case POSTCODE_VALIDATION_SUCCESS:
      return flow(
        referralCodeLens,
        l => l.set(action.payload.promoReferralCode ?? state.code),
        referralIsAutoLens,
        l => l.set(action.payload.promoReferralCode != null),
      )(state)
    default:
      return state
  }
}

export default reducer
