import { isBefore, isToday, startOfToday } from 'date-fns'
import { find, flow, includes, isUndefined, map } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { InvalidTimeSlotError, NoSessionError, PostcodeNotSupportedError, PublishConfirmationError } from '../../errors'
import { isDefined, isPresent } from '../../helpers'
import {
  ActionFactory,
  BodyItem,
  FailureActionFactory,
  Nullable,
  Optional,
  PayloadActionFactory,
  PriceRange,
  Transform,
} from '../../types/core'
import { StatelessOptionalPayloadThunk, StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import { fetchAdvert } from '../advert/actions'
import { FETCH_ADVERT_SUCCESS } from '../advert/types/actions'
import { withEndpoint } from '../app/helpers'
import { fetchMetaStep } from '../meta/actions'
import { isCustomerMetaPresent } from '../meta/helpers'
import { isDateTimeSlotPayload, isEditableAdvert, toPublishListingParams } from '../new-advert/helpers'
import { createElmerCustomer } from '../payment/actions'
import { CREATE_ELMER_CUSTOMER_SUCCESS } from '../payment/types/actions'
import { checkPostcode, validatePostcode } from '../postcode/actions'
import { POSTCODE_SUPPORTED_FAILURE } from '../postcode/types'
import { getTimeSlots } from '../time-slots/actions'
import { GET_TIME_SLOTS_SUCCESS } from '../time-slots/types'
import { fetchFavouriteCollectorsApi, priceSuggestionApi, publishAdvertApi } from './api'
import {
  CLEAR_NEW_ADVERT,
  ClearNewAdvert,
  CLOSE_INVALID_ADDRESS_MODAL,
  CLOSE_INVALID_TIME_SLOT_MODAL,
  CloseInvalidAddressModal,
  CloseInvalidTimeSlotModal,
  CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE,
  CONFIRM_PUBLISH_ADVERT_FLOW_START,
  CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS,
  ConfirmPublishAdvertFlowFailure,
  ConfirmPublishAdvertFlowResult,
  ConfirmPublishAdvertFlowStart,
  ConfirmPublishAdvertFlowSuccess,
  ConfirmPublishConfig,
  ConfirmPublishPayload,
  DISMISS_PUBLISH_FAILURE_MODAL,
  DISMISS_PUBLISH_INTENT_FEEDBACK,
  DISMISS_PUBLISH_SUCCESS_MODAL,
  DismissPublishFailureModal,
  DismissPublishIntentFeedback,
  DismissPublishSuccessModal,
  LogPublish,
  LogPublishPayload,
  NEW_ADVERT_FAVOURITE_COLLECTORS_FAILURE,
  NEW_ADVERT_FAVOURITE_COLLECTORS_START,
  NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS,
  NewAdvertFavouriteCollectors,
  NewAdvertFavouriteCollectorsFailure,
  NewAdvertFavouriteCollectorsResult,
  NewAdvertFavouriteCollectorsStart,
  NewAdvertFavouriteCollectorsSuccess,
  NewAdvertResource,
  PRICE_SUGGESTION_FAILURE,
  PRICE_SUGGESTION_START,
  PRICE_SUGGESTION_SUCCESS,
  PriceSuggestionFailure,
  PriceSuggestionPayload,
  PriceSuggestionResult,
  PriceSuggestionStart,
  PriceSuggestionSuccess,
  PUBLISH_ADVERT_FAILURE,
  PUBLISH_ADVERT_FLOW_FAILURE,
  PUBLISH_ADVERT_FLOW_START,
  PUBLISH_ADVERT_FLOW_SUCCESS,
  PUBLISH_ADVERT_START,
  PUBLISH_ADVERT_SUCCESS,
  PublishAdvertFailure,
  PublishAdvertFailureError,
  PublishAdvertFlowFailure,
  PublishAdvertFlowResult,
  PublishAdvertFlowStart,
  PublishAdvertFlowSuccess,
  PublishAdvertParams,
  PublishAdvertResult,
  PublishAdvertStart,
  PublishAdvertStartPayloadData,
  PublishAdvertSuccess,
  PublishConfig,
  PublishedAdvert,
  REMOVE_NEW_ADVERT_ASSET,
  RemoveNewAdvertAsset,
  RetrievePublishUrlPayload,
  SAVE_ADVERT_ID_AND_URL,
  SAVE_NEW_ADVERT_ADDRESS,
  SAVE_NEW_ADVERT_ADDRESS_ID,
  SAVE_NEW_ADVERT_ADDRESS_VALUE,
  SAVE_NEW_ADVERT_COLLECTION_POINT,
  SAVE_NEW_ADVERT_DETAILS,
  SAVE_NEW_ADVERT_FIXED_TIME_SLOT,
  SAVE_NEW_ADVERT_IMAGE,
  SAVE_NEW_ADVERT_IMAGES,
  SAVE_NEW_ADVERT_IS_REUSE_ONLY,
  SAVE_NEW_ADVERT_REUSABLE,
  SAVE_NEW_ADVERT_SELECTED_DATE,
  SAVE_NEW_ADVERT_SELECTED_TIME_SLOT,
  SAVE_NEW_ADVERT_SUMMARY,
  SAVE_NEW_ADVERT_TIME_SLOT,
  SAVE_NEW_ADVERT_TITLE,
  SAVE_NEW_ADVERT_VIDEO,
  SaveAdvertIdAndUrlData,
  SaveNewAdvertAddress,
  SaveNewAdvertAddressId,
  SaveNewAdvertAddressValue,
  SaveNewAdvertCollectionPoint,
  SaveNewAdvertDetails,
  SaveNewAdvertFixedTimeSlot,
  SaveNewAdvertImage,
  SaveNewAdvertImages,
  SaveNewAdvertIsReuseOnly,
  SaveNewAdvertReusable,
  SaveNewAdvertSelectedDate,
  SaveNewAdvertSelectedTimeSlot,
  SaveNewAdvertSummary,
  SaveNewAdvertTimeSlot,
  SaveNewAdvertTitle,
  SaveNewAdvertVideo,
  SET_ADVERT_CREATION_TIMEOUT,
  SET_ADVERT_CREATION_TOO_MANY,
  SET_HAZARDOUS_WASTE_ERROR,
  SET_MANUAL_ADDRESS,
  SET_PARTNER,
  SET_SELECTED_COLLECTORS,
  SET_SELECTED_COLLECTORS_LOCKED,
  SetAdvertCreationTimeout,
  SetAdvertCreationTooMany,
  SetHazardousWasteError,
  SetManualAddress,
  SetPartner,
  SetSelectedCollectors,
  SetSelectedCollectorsLocked,
  ValidNewAdvert,
  VIDEO_UPLOAD_FAILURE,
  VIDEO_UPLOAD_START,
  VIDEO_UPLOAD_SUCCESS,
  VideoId,
  VIDEOS_UPLOAD_FAILURE,
  VIDEOS_UPLOAD_SUCCESS,
  VideosUploadFailure,
  VideosUploadResult,
  VideosUploadSuccess,
  VideoUploadFailure,
  VideoUploadResult,
  VideoUploadStart,
  VideoUploadSuccess,
} from './types'

export const clearNewAdvert: ActionFactory<ClearNewAdvert> = action(CLEAR_NEW_ADVERT)

export const setSelectedCollectorsLocked: PayloadActionFactory<
  SetSelectedCollectorsLocked,
  SetSelectedCollectorsLocked['payload']
> = payloadAction(SET_SELECTED_COLLECTORS_LOCKED)

export const closeInvalidAddressModal: ActionFactory<CloseInvalidAddressModal> = action(CLOSE_INVALID_ADDRESS_MODAL)

export const closeInvalidTimeSlotModal: ActionFactory<CloseInvalidTimeSlotModal> = action(CLOSE_INVALID_TIME_SLOT_MODAL)

export const dismissPublishFailureModal: ActionFactory<DismissPublishFailureModal> =
  action(DISMISS_PUBLISH_FAILURE_MODAL)
export const dismissPublishSuccessModal: ActionFactory<DismissPublishSuccessModal> =
  action(DISMISS_PUBLISH_SUCCESS_MODAL)

export const dismissPublishIntentFeedback: ActionFactory<DismissPublishIntentFeedback> = action(
  DISMISS_PUBLISH_INTENT_FEEDBACK,
)

export const saveAddress: PayloadActionFactory<SaveNewAdvertAddress, SaveNewAdvertAddress['payload']> =
  payloadAction(SAVE_NEW_ADVERT_ADDRESS)

export const saveAddressId: PayloadActionFactory<SaveNewAdvertAddressId, SaveNewAdvertAddressId['payload']> =
  payloadAction(SAVE_NEW_ADVERT_ADDRESS_ID)

export const saveAddressValue: PayloadActionFactory<SaveNewAdvertAddressValue, SaveNewAdvertAddressValue['payload']> =
  payloadAction(SAVE_NEW_ADVERT_ADDRESS_VALUE)

export const saveAdvertIdAndUrl: PayloadActionFactory<SaveAdvertIdAndUrlData, SaveAdvertIdAndUrlData['payload']> =
  payloadAction(SAVE_ADVERT_ID_AND_URL)

export const saveCollectionPoint: PayloadActionFactory<
  SaveNewAdvertCollectionPoint,
  SaveNewAdvertCollectionPoint['payload']
> = payloadAction(SAVE_NEW_ADVERT_COLLECTION_POINT)

export const saveDetails: PayloadActionFactory<SaveNewAdvertDetails, SaveNewAdvertDetails['payload']> =
  payloadAction(SAVE_NEW_ADVERT_DETAILS)

export const saveIsReuseOnly: PayloadActionFactory<SaveNewAdvertIsReuseOnly, SaveNewAdvertIsReuseOnly['payload']> =
  payloadAction(SAVE_NEW_ADVERT_IS_REUSE_ONLY)

export const saveReusable: PayloadActionFactory<SaveNewAdvertReusable, SaveNewAdvertReusable['payload']> =
  payloadAction(SAVE_NEW_ADVERT_REUSABLE)

export const saveSummary: PayloadActionFactory<SaveNewAdvertSummary, SaveNewAdvertSummary['payload']> =
  payloadAction(SAVE_NEW_ADVERT_SUMMARY)

export const saveTitle: PayloadActionFactory<SaveNewAdvertTitle, SaveNewAdvertTitle['payload']> =
  payloadAction(SAVE_NEW_ADVERT_TITLE)

export const saveSelectedDate: PayloadActionFactory<SaveNewAdvertSelectedDate, SaveNewAdvertSelectedDate['payload']> =
  payloadAction(SAVE_NEW_ADVERT_SELECTED_DATE)

export const saveSelectedTimeSlot: PayloadActionFactory<
  SaveNewAdvertSelectedTimeSlot,
  SaveNewAdvertSelectedTimeSlot['payload']
> = payloadAction(SAVE_NEW_ADVERT_SELECTED_TIME_SLOT)

export const saveFixedTimeSlot: PayloadActionFactory<
  SaveNewAdvertFixedTimeSlot,
  SaveNewAdvertFixedTimeSlot['payload']
> = payloadAction(SAVE_NEW_ADVERT_FIXED_TIME_SLOT)

export const saveTimeSlot: PayloadActionFactory<SaveNewAdvertTimeSlot, SaveNewAdvertTimeSlot['payload']> =
  payloadAction(SAVE_NEW_ADVERT_TIME_SLOT)

export const publishAdvertFlowStart: ActionFactory<PublishAdvertFlowStart> = action(PUBLISH_ADVERT_FLOW_START)
export const publishAdvertFlowSuccess: ActionFactory<PublishAdvertFlowSuccess> = action(PUBLISH_ADVERT_FLOW_SUCCESS)
export const publishAdvertFlowFailure: FailureActionFactory<PublishAdvertFlowFailure> =
  failureAction(PUBLISH_ADVERT_FLOW_FAILURE)

export const confirmPublishAdvertFlowStart: ActionFactory<ConfirmPublishAdvertFlowStart> = action(
  CONFIRM_PUBLISH_ADVERT_FLOW_START,
)
export const confirmPublishAdvertFlowSuccess: ActionFactory<ConfirmPublishAdvertFlowSuccess> = action(
  CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS,
)
export const confirmPublishAdvertFlowFailure: FailureActionFactory<ConfirmPublishAdvertFlowFailure> = failureAction(
  CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE,
)

export const publishAdvertStart: PayloadActionFactory<PublishAdvertStart, PublishAdvertStart['payload']> =
  payloadAction(PUBLISH_ADVERT_START)
export const publishAdvertSuccess: PayloadActionFactory<PublishAdvertSuccess, PublishAdvertSuccess['payload']> =
  payloadAction(PUBLISH_ADVERT_SUCCESS)
export const publishAdvertFailure: FailureActionFactory<PublishAdvertFailure, PublishAdvertFailure['payload']> =
  failureAction(PUBLISH_ADVERT_FAILURE)

export const publishAdvertActionBundle: Transform<
  PublishAdvertStart['payload'],
  ActionBundle<PublishAdvertStart, PublishAdvertSuccess, PublishAdvertFailure, PublishedAdvert>
> = publishAdvertStartPayload => [
  () => publishAdvertStart(publishAdvertStartPayload),
  publishAdvertSuccess,
  e => publishAdvertFailure(new PublishAdvertFailureError(e, publishAdvertStartPayload)),
]

export const removeNewAdvertAsset: PayloadActionFactory<RemoveNewAdvertAsset, RemoveNewAdvertAsset['payload']> =
  payloadAction(REMOVE_NEW_ADVERT_ASSET)

export const saveImage: PayloadActionFactory<SaveNewAdvertImage, SaveNewAdvertImage['payload']> =
  payloadAction(SAVE_NEW_ADVERT_IMAGE)
export const saveImages: PayloadActionFactory<SaveNewAdvertImages, SaveNewAdvertImages['payload']> =
  payloadAction(SAVE_NEW_ADVERT_IMAGES)
export const saveVideo: PayloadActionFactory<SaveNewAdvertVideo, SaveNewAdvertVideo['payload']> =
  payloadAction(SAVE_NEW_ADVERT_VIDEO)

const priceSuggestionSuccess: PayloadActionFactory<PriceSuggestionSuccess, PriceSuggestionSuccess['payload']> =
  payloadAction(PRICE_SUGGESTION_SUCCESS)
const priceSuggestionFailure: FailureActionFactory<PriceSuggestionFailure> = failureAction(PRICE_SUGGESTION_FAILURE)
const priceSuggestionActionBundle: ActionBundle<
  PriceSuggestionStart,
  PriceSuggestionSuccess,
  PriceSuggestionFailure,
  Nullable<PriceRange>
> = [action(PRICE_SUGGESTION_START), priceSuggestionSuccess, priceSuggestionFailure]

export const priceSuggestion: StatelessPayloadThunk<PriceSuggestionPayload, PriceSuggestionResult> = ({
  builderWaste,
  easyAccess,
  reusable,
  size,
  title,
}) =>
  withEndpoint(
    'priceSuggestion',
    url => dispatch => {
      if (isUndefined(builderWaste) || isUndefined(reusable) || isUndefined(size) || !isPresent(title))
        return Promise.resolve(dispatch(priceSuggestionSuccess(null)))

      return dispatch(
        apiAction(priceSuggestionActionBundle)(
          priceSuggestionApi(url, {
            isBuilderWaste: builderWaste,
            isEasyAccess: easyAccess,
            isReusable: reusable,
            size,
            title,
          }),
        ),
      )
    },
    priceSuggestionFailure,
  )

export const setManualAddress: PayloadActionFactory<SetManualAddress, SetManualAddress['payload']> =
  payloadAction(SET_MANUAL_ADDRESS)

export const setPartner: PayloadActionFactory<SetPartner, SetPartner['payload']> = payloadAction(SET_PARTNER)

export const setHazardousWasteError: PayloadActionFactory<SetHazardousWasteError, SetHazardousWasteError['payload']> =
  payloadAction(SET_HAZARDOUS_WASTE_ERROR)

export const videoUploadActionBundle: ActionBundle<VideoUploadStart, VideoUploadSuccess, VideoUploadFailure, VideoId> =
  [action(VIDEO_UPLOAD_START), payloadAction(VIDEO_UPLOAD_SUCCESS), failureAction(VIDEO_UPLOAD_FAILURE)]

export const isVideoUploadSuccess = (action: VideoUploadResult): action is VideoUploadSuccess =>
  action.type === VIDEO_UPLOAD_SUCCESS

export const isVideosUploadSuccess = (action: VideosUploadResult): action is VideosUploadSuccess =>
  action.type === VIDEOS_UPLOAD_SUCCESS

export const videosUploadSuccess: PayloadActionFactory<VideosUploadSuccess, VideosUploadSuccess['payload']> =
  payloadAction(VIDEOS_UPLOAD_SUCCESS)
export const videosUploadFailure: FailureActionFactory<VideosUploadFailure> = failureAction(VIDEOS_UPLOAD_FAILURE)

const toPublishAdvertStartPayload: Transform<
  PublishAdvertStartPayloadData,
  Optional<PublishAdvertStart['payload']>
> = ({ body, customerRef }) => {
  const advertDataBodyItem = find<BodyItem>({ name: 'advertData' })(body)
  if (!advertDataBodyItem) return undefined

  const resource: NewAdvertResource = JSON.parse(advertDataBodyItem.value)

  return { ...resource, customerRef }
}

export const retrievePublishUrl: Transform<RetrievePublishUrlPayload, string> = ({
  advertCreationUrl,
  getAdvert,
  newAdvert,
}) => {
  if (!isEditableAdvert(newAdvert)) return advertCreationUrl

  const advert = getAdvert(newAdvert)

  if (!advert) return advertCreationUrl

  const { editAndRepost, repost } = advert.actions

  return editAndRepost ? editAndRepost.details.repost.url : repost ? repost.url : advertCreationUrl
}

export const sendPublish: Transform<PublishAdvertParams, StatelessPayloadThunk<BodyItem[], PublishAdvertResult>> =
  params => body => dispatch =>
    dispatch(
      apiAction(publishAdvertActionBundle(toPublishAdvertStartPayload({ body, customerRef: params.customerRef })))(
        publishAdvertApi(params)(body),
      ),
    )

const fetchFavouriteCollectorsFailure: FailureActionFactory<NewAdvertFavouriteCollectorsFailure> = failureAction(
  NEW_ADVERT_FAVOURITE_COLLECTORS_FAILURE,
)

const fetchFavouriteCollectorsActionBundle: ActionBundle<
  NewAdvertFavouriteCollectorsStart,
  NewAdvertFavouriteCollectorsSuccess,
  NewAdvertFavouriteCollectorsFailure,
  NewAdvertFavouriteCollectors
> = [
  action(NEW_ADVERT_FAVOURITE_COLLECTORS_START),
  payloadAction(NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS),
  fetchFavouriteCollectorsFailure,
]

export const fetchNewAdvertFavouriteCollectors: StatelessPayloadThunk<string, NewAdvertFavouriteCollectorsResult> =
  url => dispatch =>
    dispatch(apiAction(fetchFavouriteCollectorsActionBundle)(fetchFavouriteCollectorsApi(url)))

export const setSelectedCollectors: PayloadActionFactory<SetSelectedCollectors, SetSelectedCollectors['payload']> =
  payloadAction(SET_SELECTED_COLLECTORS)

export const setAdvertCreationTimeout: PayloadActionFactory<
  SetAdvertCreationTimeout,
  SetAdvertCreationTimeout['payload']
> = payloadAction(SET_ADVERT_CREATION_TIMEOUT)

export const setAdvertCreationTooMany: PayloadActionFactory<
  SetAdvertCreationTooMany,
  SetAdvertCreationTooMany['payload']
> = payloadAction(SET_ADVERT_CREATION_TOO_MANY)

export const validateTimeSlot: StatelessPayloadThunk<ValidNewAdvert, boolean> =
  ({ fixedTimeSlot, timeSlot: rangeTimeSlot }) =>
  dispatch =>
    dispatch(getTimeSlots()).then(action => {
      if (action.type === GET_TIME_SLOTS_SUCCESS) {
        const { today, future } = action.payload

        if (fixedTimeSlot) {
          const { date, timeSlot } = fixedTimeSlot

          return (
            !isBefore(date, startOfToday()) &&
            includes(timeSlot)(map('name')(isToday(date) ? today.fixed : future.fixed))
          )
        } else if (rangeTimeSlot) {
          if (!isDateTimeSlotPayload(rangeTimeSlot)) return true

          const { date, timeSlot } = rangeTimeSlot

          return (
            !isBefore(date, startOfToday()) && isDefined(find(timeSlot)(isToday(date) ? today.ranges : future.ranges))
          )
        }
      }

      return false
    })

export const createPublish: Transform<PublishConfig, StatelessThunk<PublishAdvertFlowResult>> =
  ({ isLoggedIn, logVisitSummary, onError, onSessionError, onSuccess, validNewAdvert }) =>
  () =>
  dispatch => {
    dispatch(publishAdvertFlowStart())

    return validNewAdvert
      .then(advert => {
        const { postcode } = advert.address

        return dispatch(validatePostcode(postcode)).then(result => {
          if (result.type === 'POSTCODE_VALID_FAILURE') {
            return publishAdvertFlowFailure(new PostcodeNotSupportedError())
          }

          return dispatch(checkPostcode(postcode)).then(action => {
            if (action.type === POSTCODE_SUPPORTED_FAILURE) {
              return publishAdvertFlowFailure(action.payload)
            }

            if (!action.payload) {
              return publishAdvertFlowFailure(new PostcodeNotSupportedError())
            }

            if (!isLoggedIn) {
              onSessionError()

              return publishAdvertFlowFailure(new NoSessionError())
            }

            onSuccess()
            logVisitSummary()

            return publishAdvertFlowSuccess()
          })
        })
      })
      .catch((error: Error) => {
        // `publish` action may be called from other screen, i.e. auto-publish after successful login.
        onError()

        return publishAdvertFlowFailure(error)
      })
      .then(action => dispatch(action))
  }

export const createConfirmPublish: Transform<
  ConfirmPublishConfig,
  StatelessOptionalPayloadThunk<ConfirmPublishPayload, ConfirmPublishAdvertFlowResult>
> =
  ({ extraLogData, getAdvert, hasPaymentMethod, meta, onInvalidTimeSlot, reconfirm, sendPublish, validatedAdvert }) =>
  ({ firstCall = true } = {}) =>
    withEndpoint(
      'customers',
      elmerCustomersUrl => dispatch => {
        dispatch(confirmPublishAdvertFlowStart())

        return validatedAdvert
          .then(advert =>
            dispatch(validateTimeSlot(advert)).then(valid => {
              if (!valid) {
                onInvalidTimeSlot(advert)

                return confirmPublishAdvertFlowFailure(new InvalidTimeSlotError())
              }

              return isCustomerMetaPresent(meta)
                ? sendPublish({
                    advert,
                    customerRef: meta.customerInfo.reference,
                    extraLogData,
                    hasPaymentMethod,
                    url: retrievePublishUrl({
                      advertCreationUrl: meta.advertCreationUrl,
                      newAdvert: advert,
                      getAdvert,
                    }),
                  }).then(action =>
                    action.type === PUBLISH_ADVERT_SUCCESS
                      ? confirmPublishAdvertFlowSuccess()
                      : confirmPublishAdvertFlowFailure(action.payload),
                  )
                : firstCall
                ? dispatch(createElmerCustomer(elmerCustomersUrl))
                    .then(fetchMetaStep(CREATE_ELMER_CUSTOMER_SUCCESS))
                    .then(action => dispatch(action))
                    .then(action =>
                      action.type === CREATE_ELMER_CUSTOMER_SUCCESS
                        ? reconfirm({ firstCall: false })
                        : confirmPublishAdvertFlowFailure(action.payload),
                    )
                : confirmPublishAdvertFlowFailure(new PublishConfirmationError())
            }),
          )
          .catch(confirmPublishAdvertFlowFailure)
          .then(action => dispatch(action))
      },
      confirmPublishAdvertFlowFailure,
    )

export const logPublish: Transform<LogPublish, StatelessPayloadThunk<LogPublishPayload, void>> =
  logger =>
  ({ advertUrl, customerRef, extraLogData, suggestedPriceRange }) =>
  dispatch =>
    dispatch(fetchAdvert(advertUrl)).then(action => {
      if (action.type === FETCH_ADVERT_SUCCESS)
        flow(
          toPublishListingParams({ customerRef, suggestedPriceRange }),
          params => ({ ...params, ...extraLogData }),
          logger,
        )(action.payload.body)
    })
