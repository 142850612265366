import { get, post, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import {
  CheckReferralCodeParams,
  CheckReferralCodeResponse,
  GetReferralCodeResponse,
  ReferralCodeData,
  ReferralInfo,
  RegisterReferralCodeParams,
} from './types'

export const checkReferralCodeApi = (
  url: string,
  { referralCode, postcode }: CheckReferralCodeParams,
): CompoundApi<CheckReferralCodeResponse, ReferralInfo> => [
  get(url, { params: { postcode, 'referral-code': referralCode } }),
  responseToBody,
]

export const getReferralCodeApi = (url: string): CompoundApi<GetReferralCodeResponse, ReferralCodeData> => [
  put(url),
  responseToBody,
]

export const registerReferralCodeApi = (url: string, { identifier, code }: RegisterReferralCodeParams): SimpleApi =>
  post(url, { identifier }, { params: { 'referral-code': code }, skipSessionHeader: true })
