import { flow } from 'lodash'

import Reducer from '../../types/reducer'
import {
  acceptingCounterOfferUrlLens,
  addFavouriteCollectorErrorLens,
  addFavouriteCollectorIntentLens,
  adjustPricePayloadLens,
  collectorsOffersLens,
  confirmationPayloadLens,
  isAddingCollectorToFavouritesLens,
  isCancellingLens,
  isConfirmingLens,
  isIncreasingLens,
  isPaymentProcessingLens,
  isReviewingLens,
  junkReusersOffersLens,
  showAddCardModalLens,
  showDeleteAdvertModalLens,
} from './lens'
import State, { customerAdvertDefaultState as defaultState } from './state'
import {
  ACCEPT_COUNTER_OFFER_FAILURE,
  ACCEPT_COUNTER_OFFER_START,
  ACCEPT_COUNTER_OFFER_SUCCESS,
  ADD_FAVOURITE_COLLECTOR_FAILURE,
  ADD_FAVOURITE_COLLECTOR_START,
  ADD_FAVOURITE_COLLECTOR_SUCCESS,
  ADJUST_PRICE_SUCCESS,
  CANCEL_ADVERT_FAILURE,
  CANCEL_ADVERT_START,
  CANCEL_ADVERT_SUCCESS,
  CLEAR_COLLECTORS_OFFERS,
  CLEAR_JUNK_REUSERS_OFFERS,
  CLOSE_ADD_CARD_MODAL,
  COLLECTION_PAYMENT_CANCELLED,
  COLLECTION_PAYMENT_FAILURE,
  COLLECTION_PAYMENT_START,
  COLLECTION_PAYMENT_SUCCESS,
  COLLECTION_REVIEW_FAILURE,
  COLLECTION_REVIEW_START,
  COLLECTION_REVIEW_SUCCESS,
  DELETE_ADVERT_INTENT,
  DISMISS_DELETE_ADVERT_INTENT,
  FETCH_COLLECTORS_OFFERS_SUCCESS,
  FETCH_JUNK_REUSERS_OFFERS_SUCCESS,
  INCREASE_PRICE_FAILURE,
  INCREASE_PRICE_START,
  INCREASE_PRICE_SUCCESS,
  PAYMENT_POLLING_END,
  PAYMENT_POLLING_START,
  SET_ADD_FAVOURITE_COLLECTOR_INTENT,
  SET_ADJUST_PRICE_PAYLOAD,
  SET_CONFIRMATION_PAYLOAD,
  SHOW_ADD_CARD_MODAL,
} from './types/actions'

export const customerAdvertReducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case ADD_FAVOURITE_COLLECTOR_START:
      return flow(
        isAddingCollectorToFavouritesLens,
        l => l.set(true),
        addFavouriteCollectorErrorLens,
        l => l.set(false),
      )(state)
    case ADD_FAVOURITE_COLLECTOR_SUCCESS:
      return flow(
        isAddingCollectorToFavouritesLens,
        l => l.set(false),
        addFavouriteCollectorIntentLens,
        l => l.set(null),
      )(state)
    case ADD_FAVOURITE_COLLECTOR_FAILURE:
      return flow(
        isAddingCollectorToFavouritesLens,
        l => l.set(false),
        addFavouriteCollectorErrorLens,
        l => l.set(true),
      )(state)
    case ADJUST_PRICE_SUCCESS:
      return adjustPricePayloadLens(state).set(null)
    case COLLECTION_PAYMENT_START:
      return isConfirmingLens(state).set(true)
    case COLLECTION_PAYMENT_SUCCESS:
      return flow(
        isConfirmingLens,
        l => l.set(false),
        confirmationPayloadLens,
        l => l.set(null),
      )(state)
    case COLLECTION_PAYMENT_CANCELLED:
    case COLLECTION_PAYMENT_FAILURE:
      return isConfirmingLens(state).set(false)
    case CANCEL_ADVERT_START:
      return isCancellingLens(state).set(true)
    case CANCEL_ADVERT_FAILURE:
    case CANCEL_ADVERT_SUCCESS:
      return isCancellingLens(state).set(false)
    case INCREASE_PRICE_START:
      return isIncreasingLens(state).set(true)
    case INCREASE_PRICE_FAILURE:
    case INCREASE_PRICE_SUCCESS:
      return isIncreasingLens(state).set(false)
    case PAYMENT_POLLING_START:
      return isPaymentProcessingLens(state).set(true)
    case PAYMENT_POLLING_END:
      return isPaymentProcessingLens(state).set(false)
    case COLLECTION_REVIEW_START:
      return isReviewingLens(state).set(true)
    case COLLECTION_REVIEW_SUCCESS:
    case COLLECTION_REVIEW_FAILURE:
      return isReviewingLens(state).set(false)
    case SET_ADD_FAVOURITE_COLLECTOR_INTENT:
      return addFavouriteCollectorIntentLens(state).set(action.payload)
    case SET_ADJUST_PRICE_PAYLOAD:
      return adjustPricePayloadLens(state).set(action.payload)
    case SET_CONFIRMATION_PAYLOAD:
      return confirmationPayloadLens(state).set(action.payload)
    case CLOSE_ADD_CARD_MODAL:
      return showAddCardModalLens(state).set(null)
    case SHOW_ADD_CARD_MODAL:
      return showAddCardModalLens(state).set(action.payload)
    case ACCEPT_COUNTER_OFFER_START:
      return acceptingCounterOfferUrlLens(state).set(action.payload)
    case ACCEPT_COUNTER_OFFER_FAILURE:
    case ACCEPT_COUNTER_OFFER_SUCCESS:
      return acceptingCounterOfferUrlLens(state).set(null)
    case DELETE_ADVERT_INTENT:
      return showDeleteAdvertModalLens(state).set(action.payload)
    case DISMISS_DELETE_ADVERT_INTENT:
      return showDeleteAdvertModalLens(state).set(null)
    case CLEAR_COLLECTORS_OFFERS:
      return collectorsOffersLens(state).set([])
    case FETCH_COLLECTORS_OFFERS_SUCCESS:
      return collectorsOffersLens(state).set(action.payload)
    case CLEAR_JUNK_REUSERS_OFFERS:
      return junkReusersOffersLens(state).set([])
    case FETCH_JUNK_REUSERS_OFFERS_SUCCESS:
      return junkReusersOffersLens(state).set(action.payload)
    default:
      return state
  }
}

export default customerAdvertReducer
