import { useCallback, useEffect, useMemo, useState } from 'react'

import { SignupPayload, SupplementarySignupPayload } from '../entities/signup/types'
import { isPresent } from '../helpers'
import {
  AuthIdentifierType,
  AuthIdentifierValue,
  Dispatch,
  DispatchAsync,
  DispatchOptional,
  GetAsync,
  NoOp,
  Optional,
} from '../types/core'
import { isEmail, isPhoneNumber } from '../validations'

type IdentifierData = Record<AuthIdentifierValue, AuthIdentifierValue>

type Payload = {
  defaultEmail?: string
  defaultFirstName?: string
  defaultLastName?: string
  emailSignup: boolean
  executeRecaptcha: DispatchAsync<string, Optional<string>>
  isCollector: boolean
  isCustomer: boolean
  isSigningUp: boolean
  isSupplementary?: boolean
  logClickOnCreateAccount: NoOp
  postcode?: string
  phoneNumberProp?: string
  referralCodeProp?: string
  referralError: boolean
  referralIsAuto: boolean
  signup: Dispatch<SignupPayload>
  supplementarySignup: Dispatch<SupplementarySignupPayload>
}

type Result = {
  email: string
  emailError?: string
  firstName: string
  firstNameError?: string
  isConfirmed: boolean
  isReferralVisible: boolean
  lastName: string
  lastNameError?: string
  onEmailChange: Dispatch<string>
  onSubmit: GetAsync
  phoneError?: string
  phoneNumber: string
  receiveOffers: boolean
  referralCode?: string
  referralCodeError?: string
  setConfirmed: Dispatch<boolean>
  setFirstName: Dispatch<string>
  setLastName: Dispatch<string>
  setPhoneNumber: Dispatch<string>
  setReceiveOffers: Dispatch<boolean>
  setReferralCode: DispatchOptional<string>
  termsError?: string
  toggleReferralCode: NoOp
}

export const useSignup = ({
  defaultEmail = '',
  defaultFirstName = '',
  defaultLastName = '',
  emailSignup,
  executeRecaptcha,
  isCustomer,
  isCollector,
  isSigningUp,
  isSupplementary,
  logClickOnCreateAccount,
  postcode,
  phoneNumberProp,
  referralCodeProp,
  referralError,
  referralIsAuto,
  signup,
  supplementarySignup,
}: Payload): Result => {
  const isReferralCodeDefined = referralCodeProp != null
  const [isReferralVisible, setReferralVisible] = useState<boolean>(() => {
    return referralIsAuto && isReferralCodeDefined
  })
  const toggleReferralCode = useCallback(() => setReferralVisible(p => !p), [])
  const referralCodeError = useMemo(() => (referralError ? 'Code invalid' : undefined), [referralError])
  const [referralCode, setReferralCode] = useState(referralCodeProp)
  const [isDirty, setDirty] = useState<boolean>(false)
  const [isConfirmed, setConfirmed] = useState<boolean>(false)
  const [receiveOffers, setReceiveOffers] = useState<boolean>(true)
  const [email, setEmail] = useState<string>(defaultEmail)
  const [firstName, setFirstName] = useState<string>(defaultFirstName)
  const [lastName, setLastName] = useState<string>(defaultLastName)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const identifierType = useMemo<AuthIdentifierType>(
    () => (isCustomer && emailSignup ? 'email' : 'phone'),
    [emailSignup, isCustomer],
  )
  const identifierData = useMemo<IdentifierData>(() => ({ email, phone: phoneNumber }), [email, phoneNumber])
  const identifier = useMemo<AuthIdentifierValue>(
    () => identifierData[identifierType],
    [identifierData, identifierType],
  )
  const isEmailValid = useMemo(() => isEmail(email), [email])
  const isFirstNameValid = useMemo(() => isPresent(firstName), [firstName])
  const isLastNameValid = useMemo(() => isPresent(lastName), [lastName])
  const isPhoneNumberValid = useMemo(() => isPhoneNumber(phoneNumber), [phoneNumber])
  const onEmailChange = useCallback((email: string) => {
    const trimmedEmail = email.trim()

    setEmail(trimmedEmail)
  }, [])
  const emailError = useMemo(
    () => (isDirty ? (isPresent(email) ? (isEmail(email) ? undefined : 'Email invalid') : 'Required') : undefined),
    [email, isDirty],
  )
  const firstNameError = useMemo(
    () => (isDirty && !isFirstNameValid ? 'Required' : undefined),
    [isDirty, isFirstNameValid],
  )
  const lastNameError = useMemo(
    () => (isDirty && !isLastNameValid ? 'Required' : undefined),
    [isDirty, isLastNameValid],
  )
  const phoneError = useMemo(
    () => (isDirty && !isPhoneNumberValid ? 'Invalid phone number' : undefined),
    [isDirty, isPhoneNumberValid],
  )
  const termsError = useMemo(
    () => (isDirty && !isConfirmed ? 'Please accept terms' : undefined),
    [isDirty, isConfirmed],
  )
  const isValid = useMemo(
    () =>
      isSupplementary
        ? isEmailValid
        : isConfirmed && isEmailValid && isFirstNameValid && isLastNameValid && isPhoneNumberValid,
    [isConfirmed, isEmailValid, isFirstNameValid, isLastNameValid, isPhoneNumberValid, isSupplementary],
  )
  const receiveMarketingOffers = isCollector ? true : receiveOffers

  const onSubmit = useCallback(async () => {
    setDirty(true)

    if (isSigningUp || !isValid) return

    if (isSupplementary) {
      supplementarySignup({ email })
    } else {
      const recaptchaToken = await executeRecaptcha('signup')

      signup({
        email,
        firstName,
        identifier,
        identifierType,
        lastName,
        phoneNumber,
        postcode,
        recaptchaToken,
        receiveMarketingOffers,
      })
    }

    logClickOnCreateAccount()
  }, [
    email,
    executeRecaptcha,
    firstName,
    identifier,
    identifierType,
    isSigningUp,
    isSupplementary,
    isValid,
    lastName,
    logClickOnCreateAccount,
    phoneNumber,
    postcode,
    receiveMarketingOffers,
    signup,
    supplementarySignup,
  ])

  useEffect(() => {
    setPhoneNumber(phoneNumberProp ?? '')
  }, [phoneNumberProp])

  useEffect(() => {
    if (referralIsAuto && isReferralCodeDefined) {
      setReferralVisible(true)
    }
  }, [isReferralCodeDefined, referralIsAuto])

  useEffect(() => {
    setReferralCode(referralCodeProp)
  }, [referralCodeProp])

  return {
    email,
    emailError,
    firstName,
    firstNameError,
    isConfirmed,
    receiveOffers,
    isReferralVisible,
    lastName,
    lastNameError,
    onEmailChange,
    onSubmit,
    phoneError,
    phoneNumber,
    referralCode,
    referralCodeError,
    setConfirmed,
    setFirstName,
    setLastName,
    setReceiveOffers,
    setPhoneNumber,
    setReferralCode,
    termsError,
    toggleReferralCode,
  }
}
