import { constant, find, includes, isUndefined, toLower } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import { emitTrackingEventsStep } from '../../action-helpers'
import apiAction, { ActionBundle } from '../../api-action'
import { NotCustomerError } from '../../errors'
import { payloadActionStep, timeout } from '../../helpers'
import {
  ActionFactory,
  Dispatch,
  FailureActionFactory,
  Nullable,
  Optional,
  PayloadActionFactory,
  Transform,
  Validation,
} from '../../types/core'
import { PayloadThunk, StatelessPayloadThunk } from '../../types/thunk'
import { fetchAdvert } from '../advert/actions'
import { AcceptCounterOfferPayload, AdvertActionPayload, AdvertBase, Rating, ratings } from '../advert/types'
import { isCustomerMetaPresent } from '../meta/helpers'
import { Meta } from '../meta/types'
import {
  acceptCounterOfferApi,
  acceptJunkLoverOfferApi,
  addFavouriteCollectorApi,
  adjustPriceApi,
  cancelAdvertApi,
  checkPaymentStatusApi,
  deleteAdvertApi,
  fetchCollectorsOffersApi,
  fetchJunkReusersOffersApi,
  getCustomerReceiptApi,
  increasePriceApi,
  initiatePaymentApi,
  relistAdvertApi,
  reviewCollectionApi,
} from './api'
import {
  AddFavouriteCollectorIntentPayload,
  AdjustPricePayload,
  CancelAdvertPayload,
  CollectorOffers,
  ConfirmationPayload,
  CustomerReceipt,
  DeleteAdvertPayload,
  IncreasePricePayload,
  InitiatePaymentPayload,
  InitiatePaymentResponseBody,
  JunkReusersOffers,
  PreferredPaymentMethodRequiredPayload,
  PurchaseListingParams,
  REVIEW_COLLECTION_CONFIRMED,
  ReviewCollectionConfirmed,
  ReviewCollectionPayload,
  ShowAddCardModalPayload,
} from './types'
import {
  ACCEPT_COUNTER_OFFER_FAILURE,
  ACCEPT_COUNTER_OFFER_START,
  ACCEPT_COUNTER_OFFER_SUCCESS,
  ACCEPT_JUNK_LOVER_OFFER_FAILURE,
  ACCEPT_JUNK_LOVER_OFFER_START,
  ACCEPT_JUNK_LOVER_OFFER_SUCCESS,
  AcceptCounterOfferFailure,
  AcceptCounterOfferResult,
  AcceptCounterOfferStart,
  AcceptCounterOfferSuccess,
  AcceptJunkLoverOfferFailure,
  AcceptJunkLoverOfferResult,
  AcceptJunkLoverOfferStart,
  AcceptJunkLoverOfferSuccess,
  ADD_FAVOURITE_COLLECTOR_FAILURE,
  ADD_FAVOURITE_COLLECTOR_START,
  ADD_FAVOURITE_COLLECTOR_SUCCESS,
  AddFavouriteCollectorFailure,
  AddFavouriteCollectorResult,
  AddFavouriteCollectorStart,
  AddFavouriteCollectorSuccess,
  ADJUST_PRICE_FAILURE,
  ADJUST_PRICE_START,
  ADJUST_PRICE_SUCCESS,
  AdjustPriceFailure,
  AdjustPriceResult,
  AdjustPriceStart,
  AdjustPriceSuccess,
  CANCEL_ADVERT_FAILURE,
  CANCEL_ADVERT_START,
  CANCEL_ADVERT_SUCCESS,
  CancelAdvertFailure,
  CancelAdvertResult,
  CancelAdvertStart,
  CancelAdvertSuccess,
  CHECK_PAYMENT_STATUS_FAILURE,
  CHECK_PAYMENT_STATUS_START,
  CHECK_PAYMENT_STATUS_SUCCESS,
  CheckPaymentStatusFailure,
  CheckPaymentStatusResult,
  CheckPaymentStatusStart,
  CheckPaymentStatusSuccess,
  CLEAR_COLLECTORS_OFFERS,
  CLEAR_JUNK_REUSERS_OFFERS,
  ClearCollectorsOffers,
  ClearJunkReusersOffers,
  CLOSE_ADD_CARD_MODAL,
  CloseAddCardModal,
  COLLECTION_PAYMENT_CANCELLED,
  COLLECTION_PAYMENT_COMPLETED,
  COLLECTION_PAYMENT_FAILURE,
  COLLECTION_PAYMENT_START,
  COLLECTION_PAYMENT_SUCCESS,
  COLLECTION_REVIEW_FAILURE,
  COLLECTION_REVIEW_START,
  COLLECTION_REVIEW_SUCCESS,
  CollectionPaymentCancelled,
  CollectionPaymentCompleted,
  CollectionPaymentFailure,
  CollectionPaymentResult,
  CollectionPaymentStart,
  CollectionPaymentSuccess,
  CollectionReviewFailure,
  CollectionReviewResult,
  CollectionReviewStart,
  CollectionReviewSuccess,
  DELETE_ADVERT_FAILURE,
  DELETE_ADVERT_INTENT,
  DELETE_ADVERT_START,
  DELETE_ADVERT_SUCCESS,
  DeleteAdvertFailure,
  DeleteAdvertIntent,
  DeleteAdvertResult,
  DeleteAdvertStart,
  DeleteAdvertSuccess,
  DISMISS_DELETE_ADVERT_INTENT,
  DismissDeleteAdvertIntent,
  EDIT_ADVERT,
  EditAdvert,
  FETCH_COLLECTORS_OFFERS_FAILURE,
  FETCH_COLLECTORS_OFFERS_START,
  FETCH_COLLECTORS_OFFERS_SUCCESS,
  FETCH_JUNK_REUSERS_OFFERS_FAILURE,
  FETCH_JUNK_REUSERS_OFFERS_START,
  FETCH_JUNK_REUSERS_OFFERS_SUCCESS,
  FetchCollectorsOffersFailure,
  FetchCollectorsOffersResult,
  FetchCollectorsOffersStart,
  FetchCollectorsOffersSuccess,
  FetchJunkReusersOffersFailure,
  FetchJunkReusersOffersResult,
  FetchJunkReusersOffersStart,
  FetchJunkReusersOffersSuccess,
  GET_CUSTOMER_RECEIPT_FAILURE,
  GET_CUSTOMER_RECEIPT_START,
  GET_CUSTOMER_RECEIPT_SUCCESS,
  GetCustomerReceiptFailure,
  GetCustomerReceiptResult,
  GetCustomerReceiptStart,
  GetCustomerReceiptSuccess,
  INCREASE_PRICE_FAILURE,
  INCREASE_PRICE_START,
  INCREASE_PRICE_SUCCESS,
  IncreasePriceFailure,
  IncreasePriceResult,
  IncreasePriceStart,
  IncreasePriceSuccess,
  INITIATE_PAYMENT_FAILURE,
  INITIATE_PAYMENT_START,
  INITIATE_PAYMENT_SUCCESS,
  InitiatePaymentFailure,
  InitiatePaymentResult,
  InitiatePaymentStart,
  InitiatePaymentSuccess,
  PAYMENT_POLLING_END,
  PAYMENT_POLLING_START,
  PaymentPollingEnd,
  PaymentPollingStart,
  PaymentStatus,
  RELIST_ADVERT_FAILURE,
  RELIST_ADVERT_START,
  RELIST_ADVERT_SUCCESS,
  RelistAdvertFailure,
  RelistAdvertResult,
  RelistAdvertStart,
  RelistAdvertSuccess,
  SET_ADD_FAVOURITE_COLLECTOR_INTENT,
  SET_ADJUST_PRICE_PAYLOAD,
  SET_CONFIRMATION_PAYLOAD,
  SetAddFavouriteCollectorIntent,
  SetAdjustPricePayload,
  SetConfirmationPayload,
  SHOW_ADD_CARD_MODAL,
  ShowAddCardModal,
} from './types/actions'
import { PaymentStrategyMap, PaymentStrategyResolver, StartPollingPayload } from './types/thunk'

export const adjustPriceActionBundle: ActionBundle<AdjustPriceStart, AdjustPriceSuccess, AdjustPriceFailure> = [
  action(ADJUST_PRICE_START),
  action(ADJUST_PRICE_SUCCESS),
  failureAction(ADJUST_PRICE_FAILURE),
]

export const adjustPrice: StatelessPayloadThunk<AdjustPricePayload, AdjustPriceResult> =
  ({ advertUrl, amount, url }) =>
  dispatch =>
    dispatch(apiAction(adjustPriceActionBundle)(adjustPriceApi(url, { amount, currency: 'GBP' }))).then(action =>
      dispatch(fetchAdvert(advertUrl)).then(() => action),
    )

const cancelAdvertActionBundle: ActionBundle<CancelAdvertStart, CancelAdvertSuccess, CancelAdvertFailure> = [
  action(CANCEL_ADVERT_START),
  action(CANCEL_ADVERT_SUCCESS),
  failureAction(CANCEL_ADVERT_FAILURE),
]

export const cancelAdvert: StatelessPayloadThunk<CancelAdvertPayload, CancelAdvertResult> =
  ({ advertUrl, notes, reasonId, url }) =>
  dispatch =>
    dispatch(apiAction(cancelAdvertActionBundle)(cancelAdvertApi(url, { notes, reasonId })))
      .then(payloadActionStep(fetchAdvert)(CANCEL_ADVERT_SUCCESS, advertUrl))
      .then<CancelAdvertResult>(dispatch)

const relistAdvertActionBundle: ActionBundle<RelistAdvertStart, RelistAdvertSuccess, RelistAdvertFailure> = [
  action(RELIST_ADVERT_START),
  action(RELIST_ADVERT_SUCCESS),
  failureAction(RELIST_ADVERT_FAILURE),
]

export const relistAdvert: StatelessPayloadThunk<CancelAdvertPayload, RelistAdvertResult> =
  ({ advertUrl, notes, reasonId, url }) =>
  dispatch =>
    dispatch(apiAction(relistAdvertActionBundle)(relistAdvertApi(url, { notes, reasonId })))
      .then(payloadActionStep(fetchAdvert)(RELIST_ADVERT_SUCCESS, advertUrl))
      .then<RelistAdvertResult>(dispatch)

export const deleteAdvertActionBundle: ActionBundle<DeleteAdvertStart, DeleteAdvertSuccess, DeleteAdvertFailure> = [
  action(DELETE_ADVERT_START),
  action(DELETE_ADVERT_SUCCESS),
  failureAction(DELETE_ADVERT_FAILURE),
]

export const deleteAdvert: PayloadThunk<DeleteAdvertPayload, DeleteAdvertResult> =
  ({ deleteUrl }) =>
  dispatch =>
    dispatch(apiAction(deleteAdvertActionBundle)(deleteAdvertApi(deleteUrl)))

export const increasePriceActionBundle: ActionBundle<IncreasePriceStart, IncreasePriceSuccess, IncreasePriceFailure> = [
  action(INCREASE_PRICE_START),
  action(INCREASE_PRICE_SUCCESS),
  failureAction(INCREASE_PRICE_FAILURE),
]

export const increasePrice: StatelessPayloadThunk<IncreasePricePayload, IncreasePriceResult> =
  ({ advertUrl, increment, url }) =>
  dispatch =>
    dispatch(apiAction(increasePriceActionBundle)(increasePriceApi(url, increment))).then(action =>
      dispatch(fetchAdvert(advertUrl)).then(() => action),
    )

const acceptCounterOfferStart: PayloadActionFactory<AcceptCounterOfferStart, string> =
  payloadAction(ACCEPT_COUNTER_OFFER_START)

export const acceptCounterOfferFailure: FailureActionFactory<AcceptCounterOfferFailure> =
  failureAction(ACCEPT_COUNTER_OFFER_FAILURE)
export const acceptCounterOfferActionBundle: Transform<
  string,
  ActionBundle<AcceptCounterOfferStart, AcceptCounterOfferSuccess, AcceptCounterOfferFailure>
> = url => [() => acceptCounterOfferStart(url), action(ACCEPT_COUNTER_OFFER_SUCCESS), acceptCounterOfferFailure]

export const acceptCounterOffer: Transform<
  Nullable<Meta>,
  StatelessPayloadThunk<AcceptCounterOfferPayload, Optional<AcceptCounterOfferResult>>
> =
  meta =>
  ({ advertUrl, prerequisites, trackingEvents, url }) =>
  dispatch =>
    isPreferredPaymentMethodRequired({ meta, prerequisites })
      ? Promise.resolve(undefined)
      : dispatch(apiAction(acceptCounterOfferActionBundle(url))(acceptCounterOfferApi(url)))
          .then(action => dispatch(fetchAdvert(advertUrl)).then(() => action))
          .then(emitTrackingEventsStep(ACCEPT_COUNTER_OFFER_SUCCESS, trackingEvents))
          .then<AcceptCounterOfferResult>(dispatch)

export const acceptJunkLoverOfferActionBundle: ActionBundle<
  AcceptJunkLoverOfferStart,
  AcceptJunkLoverOfferSuccess,
  AcceptJunkLoverOfferFailure
> = [
  action(ACCEPT_JUNK_LOVER_OFFER_START),
  action(ACCEPT_JUNK_LOVER_OFFER_SUCCESS),
  failureAction(ACCEPT_JUNK_LOVER_OFFER_FAILURE),
]

export const acceptJunkLoverOffer: Transform<
  Nullable<Meta>,
  StatelessPayloadThunk<AcceptCounterOfferPayload, Optional<AcceptJunkLoverOfferResult>>
> =
  meta =>
  ({ advertUrl, prerequisites, trackingEvents, url }) =>
  dispatch =>
    isPreferredPaymentMethodRequired({ meta, prerequisites })
      ? Promise.resolve(undefined)
      : dispatch(apiAction(acceptJunkLoverOfferActionBundle)(acceptJunkLoverOfferApi(url)))
          .then(action => dispatch(fetchAdvert(advertUrl)).then(() => action))
          .then(emitTrackingEventsStep(ACCEPT_JUNK_LOVER_OFFER_SUCCESS, trackingEvents))
          .then<AcceptJunkLoverOfferResult>(dispatch)

export const initiatePaymentActionBundle: ActionBundle<
  InitiatePaymentStart,
  InitiatePaymentSuccess,
  InitiatePaymentFailure,
  InitiatePaymentResponseBody
> = [action(INITIATE_PAYMENT_START), payloadAction(INITIATE_PAYMENT_SUCCESS), failureAction(INITIATE_PAYMENT_FAILURE)]

export const collectionPaymentStart: ActionFactory<CollectionPaymentStart> = action(COLLECTION_PAYMENT_START)
export const collectionPaymentSuccess: PayloadActionFactory<CollectionPaymentSuccess, string> =
  payloadAction(COLLECTION_PAYMENT_SUCCESS)
export const collectionPaymentCancelled: ActionFactory<CollectionPaymentCancelled> =
  action(COLLECTION_PAYMENT_CANCELLED)
export const collectionPaymentFailure: FailureActionFactory<CollectionPaymentFailure> =
  failureAction(COLLECTION_PAYMENT_FAILURE)

export const reviewCollectionConfirmed: ActionFactory<ReviewCollectionConfirmed> = action(REVIEW_COLLECTION_CONFIRMED)

export const collectionReviewFailure: FailureActionFactory<CollectionReviewFailure> =
  failureAction(COLLECTION_REVIEW_FAILURE)
export const collectionReviewActionBundle: ActionBundle<
  CollectionReviewStart,
  CollectionReviewSuccess,
  CollectionReviewFailure
> = [action(COLLECTION_REVIEW_START), action(COLLECTION_REVIEW_SUCCESS), collectionReviewFailure]

export const reviewCollection: StatelessPayloadThunk<ReviewCollectionPayload, CollectionReviewResult> =
  ({ rating: ratingValue, ...payload }) =>
  dispatch => {
    const rating = find<Rating>({ value: ratingValue })(ratings)

    if (!rating)
      return Promise.resolve(collectionReviewFailure(new Error('rating not found for value: ' + ratingValue)))

    return dispatch(apiAction(collectionReviewActionBundle)(reviewCollectionApi({ ...payload, rating: rating.name })))
      .then(payloadActionStep(fetchAdvert)(COLLECTION_REVIEW_SUCCESS, payload.advertUrl))
      .then(action => dispatch(action))
  }

export const clearCollectorsOffers: ActionFactory<ClearCollectorsOffers> = action(CLEAR_COLLECTORS_OFFERS)

export const clearJunkReusersOffers: ActionFactory<ClearJunkReusersOffers> = action(CLEAR_JUNK_REUSERS_OFFERS)

const fetchCollectorsOffersActionBundle: ActionBundle<
  FetchCollectorsOffersStart,
  FetchCollectorsOffersSuccess,
  FetchCollectorsOffersFailure,
  CollectorOffers
> = [
  action(FETCH_COLLECTORS_OFFERS_START),
  payloadAction(FETCH_COLLECTORS_OFFERS_SUCCESS),
  failureAction(FETCH_COLLECTORS_OFFERS_FAILURE),
]

const fetchJunkReusersOffersActionBundle: ActionBundle<
  FetchJunkReusersOffersStart,
  FetchJunkReusersOffersSuccess,
  FetchJunkReusersOffersFailure,
  JunkReusersOffers
> = [
  action(FETCH_JUNK_REUSERS_OFFERS_START),
  payloadAction(FETCH_JUNK_REUSERS_OFFERS_SUCCESS),
  failureAction(FETCH_JUNK_REUSERS_OFFERS_FAILURE),
]

export const fetchCollectorsOffers: StatelessPayloadThunk<string, FetchCollectorsOffersResult> = url => dispatch =>
  dispatch(apiAction(fetchCollectorsOffersActionBundle)(fetchCollectorsOffersApi(url)))

export const fetchJunkReusersOffers: StatelessPayloadThunk<string, FetchJunkReusersOffersResult> = url => dispatch =>
  dispatch(apiAction(fetchJunkReusersOffersActionBundle)(fetchJunkReusersOffersApi(url)))

export const setConfirmationPayload: PayloadActionFactory<
  SetConfirmationPayload,
  Nullable<ConfirmationPayload>
> = payloadAction(SET_CONFIRMATION_PAYLOAD)

export const showAddCardModal: PayloadActionFactory<ShowAddCardModal, ShowAddCardModalPayload> =
  payloadAction(SHOW_ADD_CARD_MODAL)
export const closeAddCardModal: ActionFactory<CloseAddCardModal> = action(CLOSE_ADD_CARD_MODAL)

export const setAdjustPricePayload: PayloadActionFactory<
  SetAdjustPricePayload,
  Nullable<AdjustPricePayload>
> = payloadAction(SET_ADJUST_PRICE_PAYLOAD)

export const editAdvert: PayloadActionFactory<EditAdvert, AdvertBase> = payloadAction(EDIT_ADVERT)

export const getCustomerReceiptActionBundle: ActionBundle<
  GetCustomerReceiptStart,
  GetCustomerReceiptSuccess,
  GetCustomerReceiptFailure,
  CustomerReceipt
> = [
  action(GET_CUSTOMER_RECEIPT_START),
  payloadAction(GET_CUSTOMER_RECEIPT_SUCCESS),
  failureAction(GET_CUSTOMER_RECEIPT_FAILURE),
]

export const getCustomerReceipt: StatelessPayloadThunk<string, GetCustomerReceiptResult> = url => dispatch =>
  dispatch(apiAction(getCustomerReceiptActionBundle)(getCustomerReceiptApi(url)))

export const deleteAdvertIntent: PayloadActionFactory<DeleteAdvertIntent, DeleteAdvertPayload> =
  payloadAction(DELETE_ADVERT_INTENT)

export const dismissDeleteAdvertIntent: ActionFactory<DismissDeleteAdvertIntent> = action(DISMISS_DELETE_ADVERT_INTENT)

export const paymentPollingStart: ActionFactory<PaymentPollingStart> = action(PAYMENT_POLLING_START)
export const paymentPollingEnd: ActionFactory<PaymentPollingEnd> = action(PAYMENT_POLLING_END)

export const PAYMENT_POLLING_INTERVAL = 5000

export const collectionPaymentCompleted: ActionFactory<CollectionPaymentCompleted> =
  action(COLLECTION_PAYMENT_COMPLETED)

const checkPaymentStatusActionBundle: ActionBundle<
  CheckPaymentStatusStart,
  CheckPaymentStatusSuccess,
  CheckPaymentStatusFailure,
  PaymentStatus
> = [
  action(CHECK_PAYMENT_STATUS_START),
  payloadAction(CHECK_PAYMENT_STATUS_SUCCESS),
  failureAction(CHECK_PAYMENT_STATUS_FAILURE),
]

export const sendConfirmCollection: StatelessPayloadThunk<InitiatePaymentPayload, InitiatePaymentResult> =
  ({ useSavedPaymentMethod, url }) =>
  dispatch =>
    dispatch(apiAction(initiatePaymentActionBundle)(initiatePaymentApi(url, { useSavedPaymentMethod })))

export const createInitiatePayment =
  <T extends string>(
    meta: Nullable<Meta>,
    paymentStrategyResolver: PaymentStrategyResolver<T>,
    paymentStrategyMap: PaymentStrategyMap<T>,
    logPurchaseListing: Dispatch<PurchaseListingParams>,
  ): StatelessPayloadThunk<ConfirmationPayload, CollectionPaymentResult> =>
  payload =>
  dispatch =>
    Promise.resolve(dispatch(collectionPaymentStart()))
      .then(() =>
        isCustomerMetaPresent(meta)
          ? paymentStrategyResolver(meta)
              .then(strategy => paymentStrategyMap[strategy](payload))
              .then(action => dispatch(action))
              .then(action => {
                const { addCard: onFailure } = paymentStrategyMap

                if (action.type === 'COLLECTION_PAYMENT_CANCELLED') {
                  return collectionPaymentCancelled()
                }

                if (action.type === COLLECTION_PAYMENT_FAILURE) {
                  return onFailure(payload)
                }

                dispatch(paymentPollingStart())
                dispatch(
                  setPollingTimeout({ ...payload, logPurchaseListing, meta, onFailure, pollUrl: action.payload }),
                )

                return action
              })
          : collectionPaymentFailure(new NotCustomerError()),
      )
      .then(action => dispatch(fetchAdvert(payload.advertUrl)).then(() => action))

const setPollingTimeout: StatelessPayloadThunk<StartPollingPayload, CheckPaymentStatusResult> = payload => dispatch =>
  timeout(PAYMENT_POLLING_INTERVAL).then(() => dispatch(onPoll(payload)))

const onPoll: StatelessPayloadThunk<StartPollingPayload, CheckPaymentStatusResult> = payload => dispatch =>
  dispatch(apiAction(checkPaymentStatusActionBundle)(checkPaymentStatusApi(payload.pollUrl))).then(action => {
    const {
      logPurchaseListing,
      meta: {
        customerInfo: { reference: customerRef },
      },
      onFailure,
      ...confirmationPayload
    } = payload
    const { advert, advertUrl } = confirmationPayload

    if (action.type === CHECK_PAYMENT_STATUS_SUCCESS) {
      switch (toLower(action.payload)) {
        case 'complete':
          return dispatch(fetchAdvert(advertUrl))
            .then(paymentPollingEnd)
            .then(dispatch)
            .then(collectionPaymentCompleted)
            .then(dispatch)
            .then(() => {
              const {
                metaDetails: { reference: advertRef },
                publicDetails: { price, title: advertTitle },
              } = advert

              logPurchaseListing({
                advertRef,
                advertTitle,
                customerRef,
                ...price,
              })

              return action
            })
        case 'collectorconfirmed':
          return dispatch(fetchAdvert(advertUrl))
            .then(paymentPollingEnd)
            .then(dispatch)
            .then(() => onFailure(confirmationPayload))
            .then(dispatch)
            .then(constant(action))
        default:
          return dispatch(setPollingTimeout(payload))
      }
    }

    return action
  })

const isPreferredPaymentMethodRequired: Validation<PreferredPaymentMethodRequiredPayload> = ({ meta, prerequisites }) =>
  (!isCustomerMetaPresent(meta) || isUndefined(meta.customerInfo.preferredPaymentMethod)) &&
  includes('setpreferredpaymentmethod')(prerequisites)

export const setAddFavouriteCollectorIntent: PayloadActionFactory<
  SetAddFavouriteCollectorIntent,
  Nullable<AddFavouriteCollectorIntentPayload>
> = payloadAction(SET_ADD_FAVOURITE_COLLECTOR_INTENT)

const addFavouriteCollectorActionBundle: ActionBundle<
  AddFavouriteCollectorStart,
  AddFavouriteCollectorSuccess,
  AddFavouriteCollectorFailure
> = [
  action(ADD_FAVOURITE_COLLECTOR_START),
  action(ADD_FAVOURITE_COLLECTOR_SUCCESS),
  failureAction(ADD_FAVOURITE_COLLECTOR_FAILURE),
]

export const addFavouriteCollector: StatelessPayloadThunk<AdvertActionPayload, AddFavouriteCollectorResult> =
  ({ advertUrl, url }) =>
  dispatch =>
    dispatch(apiAction(addFavouriteCollectorActionBundle)(addFavouriteCollectorApi(url)))
      .then(payloadActionStep(fetchAdvert)(ADD_FAVOURITE_COLLECTOR_SUCCESS, advertUrl))
      .then<AddFavouriteCollectorResult>(dispatch)
