import { Action } from 'redux'

import { NotRoleError } from '../../errors'
import {
  AuthIdentifierType,
  AuthIdentifierValue,
  AuthParams,
  Capability,
  FailureAction,
  PayloadAction,
  PayloadActionFactory,
  Transform,
} from '../../types/core'

export const SIGNUP_OTP_START = 'SIGNUP_OTP_START'
export const SIGNUP_OTP_SUCCESS = 'SIGNUP_OTP_SUCCESS'
export const SIGNUP_OTP_FAILURE = 'SIGNUP_OTP_FAILURE'

export const AUTHORISED = 'AUTHORISED'
export const CANCEL_SIGNUP = 'CANCEL_SIGNUP'
export const CLEAR_SIGNUP_ERROR = 'CLEAR_SIGNUP_ERROR'
export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA'

export type SignupOtpStart = PayloadAction<typeof SIGNUP_OTP_START, SignupPayload>
export type SignupOtpSuccess = Action<typeof SIGNUP_OTP_SUCCESS>
export type SignupOtpFailure = FailureAction<typeof SIGNUP_OTP_FAILURE>

export type Authorised = Action<typeof AUTHORISED>
export type CancelSignup = Action<typeof CANCEL_SIGNUP>
export type ClearError = Action<typeof CLEAR_SIGNUP_ERROR>
export type SetSignupData = PayloadAction<typeof SET_SIGNUP_DATA, Partial<SignupPayload>>

export type SignupOtpResult = SignupOtpSuccess | SignupOtpFailure
export type SignupOtp = SignupOtpStart | SignupOtpResult
export type SignupAction = Authorised | CancelSignup | ClearError | SignupOtp | SetSignupData

export interface SignupPayload {
  email: string
  firstName: string
  identifier: AuthIdentifierValue
  identifierType: AuthIdentifierType
  lastName: string
  phoneNumber?: string
  recaptchaToken?: string
  postcode?: string
  receiveMarketingOffers: boolean
}

export interface SupplementarySignupPayload {
  email: string
}

export interface SignupParams extends AuthParams {
  email?: string
  firstName: string
  lastName: string
  postcode?: string
  referralCode?: string
}

export interface BaseConfig<C extends Capability> {
  requiredCapability: C
  returnTo?: string
}

export type CustomerConfig = BaseConfig<'customer'>
export type CollectorConfig = BaseConfig<'collector'>
export type JunkReuserConfig = BaseConfig<'junklover'>

export type SupplierConfig = CollectorConfig | JunkReuserConfig
export type RoleConfig = CustomerConfig | SupplierConfig

type TriggerScreen = 'publish'

export interface SignupConfig {
  triggerScreens: Record<TriggerScreen, string>
}

export type TrackingIdType = 'facebookclick' | 'googleclick' | 'microsoftclick'

export type TrackingDataItem = {
  trackingId: string
  trackingIdType: TrackingIdType
}

export type SignupMeta = {
  trackingData?: TrackingDataItem[]
}

export interface CheckApprovalConfig<S, T> {
  capability: Capability
  error: NotRoleError
  isApproved: Transform<S, boolean>
  isLoggedIn: Transform<S, boolean>
  notApprovedActionFactory: PayloadActionFactory<T, boolean>
}
